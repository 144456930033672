import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, EmailBanner, TableRow } from '../components'
import { HTMLContent, BlogPostTemplate } from '../../../shared'

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  const { html, frontmatter } = post

  return (
    <Layout>
      <BlogPostTemplate
        contentComponent={HTMLContent}
        content={html}
        post={frontmatter}
        EmailBanner={EmailBanner}
        TableRow={TableRow}
        tableSpacing="2.25fr 1.5fr 1.5fr 1.5fr 1fr;"
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        amazonButtons
        productsTable {
          productName
          isTopRated
          isBestValue
          shopUrl
          tldr
          pros
          cons
          rating
          ratingStars
          image {
            childImageSharp {
              gatsbyImageData(width: 200, quality: 100)
            }
          }
        }
        bottomTitle
        bannerImage {
          childImageSharp {
            gatsbyImageData(width: 1300, quality: 100)
          }
        }
      }
    }
  }
`
